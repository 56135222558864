<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      height="100px"
    >
      <v-avatar
        class=""
        color="white lighten-5"
        size="70"
      >
        <v-img
          contain
          max-height="100%"
          src="/assets/app/static/logo-lizto.png"
        ></v-img>
      </v-avatar>

      <v-spacer></v-spacer>

      <div class="hidden-md-and-up">
        <v-btn text small @click.stop="drawer = !drawer">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </div>
      <div class="hidden-sm-and-down">
        <v-btn text small @click="handleNavigationMainPage('#banner')">{{$t('base.menu.home')}}</v-btn>
        <v-btn text small @click="handleNavigationMainPage('#features')">{{$t('base.menu.features')}}</v-btn>
        <v-btn outlined color="purple" small :href="getUrlLiztoLogin()">{{$t('base.menu.loginLizto')}}</v-btn>
        <v-btn text small @click="handleNavigationMainPage('#contact-form')">{{$t('base.menu.contact')}}</v-btn>
        <SelectLanguage/>
        <v-btn icon target="blank" :href="getUrlFacebook()"><v-icon>mdi-facebook</v-icon></v-btn>
        <v-btn icon target="blank" :href="getUrlInstagram()"><v-icon>mdi-instagram</v-icon></v-btn>
      </div>
    </v-app-bar>

    <router-view></router-view>

    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      right
      width="300"
      id="drawer"
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-img
              contain
              max-height="70px"
              src="/assets/app/static/logo-lizto.png"
            ></v-img>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn text small @click.stop="drawer = !drawer">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item>
          <v-btn text small @click="handleNavigationMainPage('#banner')">{{$t('base.menu.home')}}</v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn text small @click="handleNavigationMainPage('#features')">{{$t('base.menu.features')}}</v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn outlined color="purple" small :href="getUrlLiztoLogin()">{{$t('base.menu.loginLizto')}}</v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn text small @click="handleNavigationMainPage('#contact-form')">{{$t('base.menu.contact')}}</v-btn>
        </v-list-item>
        <v-list-item>
          <SelectLanguage/>
        </v-list-item>
        <v-list-item>
          <v-btn icon :href="getUrlFacebook()"><v-icon>mdi-facebook</v-icon></v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn icon :href="getUrlInstagram()"><v-icon>mdi-instagram</v-icon></v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!--v-btn
      dark
      bottom
      fixed
      right
      color="green"
      @click="openWhatsapp"
      style="bottom: 90px !important;padding: 25px 10px;z-index: 1000;"
    >
      <v-icon class="mr-2">mdi-whatsapp</v-icon>
      {{$t('base.text.whatsappText')}}
    </v-btn-->

    <v-footer
      color="#292929"
      dark padless
    >
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="6" lg="6">
            <!--v-img
              contain
              max-height="80px"
              src="/assets/app/static/logo-lizto-black.png"
            ></v-img-->
            <v-btn icon target="blank" :href="getUrlFacebook()">
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
            <v-btn icon target="blank" :href="getUrlInstagram()">
              <v-icon>mdi-instagram</v-icon>
            </v-btn>

            <v-row>
                <v-col cols="12">
                  <v-btn target="blank" text :href="getUrlWebTermsAndConditions()">
                    {{$t('contact.links.termsAndConditions')}}
                  </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <div class="d-flex align-items-center justify-center mt-2">
              <span class="title font-weight-light grey--text text--lighten-1 mr-3">
                {{$t('base.text.safeSite')}}
              </span>
              <span id="siteseal" class="siteseal-container"></span>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <div class="title font-weight-light grey--text text--lighten-1 text-center">
              &copy; {{ (new Date()).getFullYear() }} - Lizto
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import SelectLanguage from '@/views/components/SelectLanguage.vue'
export default {
  data () {
    return {
      drawer: false,
      scriptSealAdded: false
    }
  },
  methods: {
    goTo (path) {
      if (this.$route.path !== path) {
        this.$router.push({ path: path, query: { l: this.$route.query.l } }) // Redirige al inicio y luego a la sección.
      }
    },
    handleNavigationMainPage (section) {
      if (this.$route.path !== '/') {
        this.$router.push({ path: '/', hash: section, query: { l: this.$route.query.l } }) // Redirige al inicio y luego a la sección.
      } else {
        this.$vuetify.goTo(section)
      }
    },
    getUrlFacebook () {
      return 'https://www.facebook.com/liztosoftware'
    },
    getUrlInstagram () {
      return 'https://www.instagram.com/lizto_software'
    },
    getUrlWebTermsAndConditions () {
      if (this.$root.$i18n.locale === 'es') {
        return 'https://app.lizto.co/terms-and-conditions'
      } else if (this.$root.$i18n.locale === 'en') {
        return 'https://app.lizto.co/terms-and-conditions'
      } else {
        return 'https://app.lizto.co/terms-and-conditions'
      }
    },
    getUrlLiztoLogin () {
      if (this.$root.$i18n.locale === 'es') {
        return 'https://app.lizto.co/login'
      } else if (this.$root.$i18n.locale === 'en') {
        return 'https://app.lizto.co/login/en'
      } else {
        return 'https://app.lizto.co/login'
      }
    },
    openWhatsapp () {
      const cellphone = '+573187601559'
      const url = `https://api.whatsapp.com/send?phone=${cellphone}&text=Hola+Lizto+Software`
      window.open(url)
    },
    appendScriptSeal () {
      if (this.scriptSealAdded) { return }

      this.scriptSealAdded = true

      const sealId = 'HNLjxWtikXAvYyF57N5pwLYpl3o3uA3zmQeR06bhv2HCG4mPCuRnwXogioGF'

      const sealScript = document.createElement('script')

      sealScript.setAttribute('src', `https://seal.godaddy.com/getSeal?sealID=${sealId}`)

      document.head.appendChild(sealScript)
    },
    appendLiveChatScript () {
      // eslint-disable-next-line no-use-before-define
      window.$zoho = window.$zoho || {}

      window.$zoho.salesiq = window.$zoho.salesiq || { widgetcode: 'siq1dcfbba0a352901266aa6649bf93cc08', values: {}, ready: function () {} }

      var d = document

      var s = d.createElement('script')

      s.type = 'text/javascript'
      s.id = 'zsiqscript'
      s.defer = true
      s.src = 'https://salesiq.zoho.com/widget'

      var t = d.getElementsByTagName('script')[0]

      t.parentNode.insertBefore(s, t)

      d.write("<div id='zsiqwidget'></div>")
    }
  },
  mounted () {
    this.appendLiveChatScript()
    this.appendScriptSeal()
  },
  components: {
    SelectLanguage
  }
}
</script>

<style>
  .grecaptcha-badge { visibility: hidden; }
</style>
