<template>
  <div class="text-center d-inline-block mx-auto">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          small
          v-bind="attrs"
          v-on="on"
          width="70px"
        >
          <v-img max-width="20px" src="/assets/app/static/language-black.png"/>
          <span class="ml-1 font-weight-bold">{{ $i18n.locale.toUpperCase() }}</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="({ title, img, langKey }, i) in items"
          :key="i"
          @click="changeLanguage(langKey)"
        >
        <v-list-item-icon>
          <v-img max-width="20px" :src="`/assets/app/static/${img}`"/>
        </v-list-item-icon>
        <v-list-item-title class="ml-1 font-weight-bold">{{ title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  data () {
    return {
      items: [
        {
          title: 'ES',
          langKey: 'es',
          img: 'flag-es.png'
        },
        {
          title: 'EN',
          langKey: 'en',
          img: 'flag-en.png'
        }
      ]
    }
  },
  methods: {
    changeLanguage (locale) {
      this.$root.$i18n.locale = locale

      // const url = new URL(window.location.href.split('?')[0])

      if (locale === 'es') {
        this.$router.replace({ query: { l: null } })
        // nothing
      } else {
        // url.searchParams.set('l', locale)
        this.$router.replace({ query: { l: locale } })
      }

      // window.history.pushState({}, '', url)
    }
  }
}
</script>
