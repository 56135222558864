var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center d-inline-block mx-auto"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","small":"","width":"70px"}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"max-width":"20px","src":"/assets/app/static/language-black.png"}}),_c('span',{staticClass:"ml-1 font-weight-bold"},[_vm._v(_vm._s(_vm.$i18n.locale.toUpperCase()))])],1)]}}])},[_c('v-list',_vm._l((_vm.items),function(ref,i){
var title = ref.title;
var img = ref.img;
var langKey = ref.langKey;
return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.changeLanguage(langKey)}}},[_c('v-list-item-icon',[_c('v-img',{attrs:{"max-width":"20px","src":("/assets/app/static/" + img)}})],1),_c('v-list-item-title',{staticClass:"ml-1 font-weight-bold"},[_vm._v(_vm._s(title))])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }